<template>
  <v-container class="main-container">
    <v-row>
      <v-col cols="12">
        <h1 class="text-center mt-2 mb-8">CONDITIONS GÉNÉRALES D'UTILISATION</h1>
        <p>Les pr&eacute;sentes conditions g&eacute;n&eacute;rales d&rsquo;utilisation (ci-apr&egrave;s les &laquo; CGU &raquo;) s&rsquo;appliquent &agrave; toute personne physique ou morale qui acc&egrave;de et/ou passe commande sur le site internet www.lecrandusavoir.fr (ci-apr&egrave;s &laquo; le Site &raquo;) &eacute;dit&eacute; par la soci&eacute;t&eacute; PARTENAIRE DU SAVOIR, soci&eacute;t&eacute; par actions simplifi&eacute;e &agrave; associ&eacute; unique, immatricul&eacute;e au registre du commerce et des soci&eacute;t&eacute;s de Paris sous le num&eacute;ro 901 393 728, ayant son si&egrave;ge social 54 boulevard du Montparnasse, 75015 Paris (ci-apr&egrave;s &laquo; l&rsquo;Editeur &raquo;).</p>

        <h2 class="mb-4">PREAMBULE</h2>

        <p>Les termes employ&eacute;s avec une majuscule ci-dessous ont le sens qui leur est donn&eacute; &agrave; l&rsquo;Article 1 des pr&eacute;sentes.</p>

        <p>L&rsquo;Editeur propose, via le Site, d&rsquo;acc&eacute;der &agrave; diff&eacute;rents types de manuels scolaires num&eacute;riques et interactifs.&nbsp;</p>

        <p>L&rsquo;acc&egrave;s au Site et la consultation de ses Contenus implique pour tous les Utilisateurs et Acheteurs l&rsquo;acceptation pleine, enti&egrave;re et sans r&eacute;serve des pr&eacute;sentes CGU. Les CGU sont tenues de mani&egrave;re continue et durable &agrave; la disposition des Utilisateurs et des Acheteurs, en &eacute;tant disponibles dans la rubrique pr&eacute;vue &agrave; cet effet en bas de chaque page du Site.</p>

        <p>L&rsquo;Editeur se r&eacute;serve le droit de modifier ou compl&eacute;ter, &agrave; tout moment, tout ou partie des CGU. La nouvelle version des CGU sera disponible sur le Site avec sa date d&rsquo;entr&eacute;e en vigueur.</p>

        <h2 class="mb-4">ARTICLE 1 &ndash; DEFINITION&nbsp;</h2>

        <p>
          &laquo;&nbsp;
          <strong>Acheteur</strong>&nbsp;&raquo;&nbsp;: d&eacute;signe la personne physique ou morale, en particulier les &eacute;tablissements scolaires, qui ach&egrave;te un ou plusieurs Contenus via le Site.
        </p>

        <p>
          &laquo;&nbsp;
          <strong>Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation</strong>&raquo; ou &laquo;&nbsp;
          <strong>CGU</strong>&raquo;&nbsp;: d&eacute;signent les pr&eacute;sentes conditions g&eacute;n&eacute;rales qui encadrent les conditions et les modalit&eacute;s de fourniture des Services par l&rsquo;Editeur aux Utilisateurs.&nbsp;
        </p>

        <p>
          &laquo;&nbsp;
          <strong>Commande</strong>&nbsp;&raquo;&nbsp;: d&eacute;signe la souscription par l&rsquo;Acheteur des diff&eacute;rents Services propos&eacute;s par l&rsquo;Editeur qui seront mis &agrave; disposition des Utilisateurs. La Commande peut &ecirc;tre r&eacute;alis&eacute;e via le Site ou via un libraire adjudicateur ou via le Gestionnaire d&rsquo;Acc&egrave;s aux Ressources (GAR).
        </p>

        <p>
          &laquo;&nbsp;
          <strong>Contenu</strong>&nbsp;&raquo;&nbsp;: d&eacute;signe l&rsquo;ensemble des manuels scolaires, en ce compris les textes, images, photographies, vid&eacute;os, etc. figurant au sein de ces manuels, que l&rsquo;Editeur met &agrave; disposition des Utilisateurs autoris&eacute;s &agrave; y acc&eacute;der via le Site.&nbsp;
        </p>

        <p>
          <strong>&laquo;&nbsp;Parties</strong>&raquo; : d&eacute;signe ensemble l&rsquo;Editeur et les Utilisateurs et/ou l&rsquo;Acheteur.
        </p>

        <p>
          <strong>&laquo;&nbsp;Service</strong>&raquo; : d&eacute;signe l&#39;ensemble des services et fonctionnalit&eacute;s accessibles &agrave; partir du Site et permettant notamment d&rsquo;acc&eacute;der et utiliser des manuels scolaires num&eacute;riques interactifs.
        </p>

        <p>
          &laquo;&nbsp;
          <strong>Site</strong>&nbsp;&raquo; d&eacute;signe la plateforme en ligne de l&rsquo;Editeur par lequel l&rsquo;Utilisateur acc&egrave;de aux Contenus et qui permet &agrave; l&rsquo;Acheteur d&rsquo;effectuer des Commandes. Le Site est accessible &agrave; l&rsquo;adresse suivante
          <a
            href="http://www.lecrandusavoir.fr"
          >www.lecrandusavoir.fr</a> et s&rsquo;adresse aussi bien aux enseignants, aux &eacute;l&egrave;ves ou &agrave; leurs parents.&nbsp;
        </p>

        <p>
          &laquo;&nbsp;
          <strong>Tableau de bord</strong>&raquo;&nbsp;: d&eacute;signe l&rsquo;interface personnel d&eacute;di&eacute;e aux Utilisateurs enseignants, uniquement accessible avec un identifiant et un mot de passe sp&eacute;cifiques et personnels, leur permettant d&rsquo;acc&eacute;der &agrave; l&#39;ensemble des fonctionnalit&eacute;s du Site, aux Contenus et de suivre l&rsquo;activit&eacute; et la progression de ses &eacute;l&egrave;ves sur les Contenus propos&eacute;s.
        </p>

        <p>
          &laquo;&nbsp;
          <strong>Utilisateur</strong>&raquo; d&eacute;signe toute personne physique ou morale qui acc&egrave;de au Site et ses Contenus via un moteur de recherche ou via un lien d&rsquo;acc&egrave;s direct aux Contenus. Les Utilisateurs peuvent &ecirc;tre des enseignants, des &eacute;l&egrave;ves ou leurs parents. Les Utilisateurs &eacute;l&egrave;ves peuvent en outre &ecirc;tre des personnes mineures qui agissent alors sous l&rsquo;autorit&eacute; et la responsabilit&eacute; de leurs parents ou de leurs enseignants.&nbsp;
        </p>

        <h2 class="mb-4">ARTICLE 2 &ndash; OBJET&nbsp;</h2>

        <p>Les pr&eacute;sentes CGU constituent le socle unique de la relation commerciale entre les Parties et ont pour objet de d&eacute;finir les conditions et modalit&eacute;s selon lesquelles l&rsquo;Editeur propose diff&eacute;rents Services aux Utilisateurs par l&rsquo;interm&eacute;diaire de son Site.</p>

        <p>Les pr&eacute;sentes CGU r&eacute;gissent tout acc&egrave;s au Site et toute &eacute;ventuelle Commande pass&eacute;e sur le Site ou &agrave; la suite d&rsquo;une demande de devis &agrave; l&rsquo;Editeur.&nbsp;</p>

        <p>Il est express&eacute;ment convenu entre les Parties que les Services sont susceptibles d&rsquo;&eacute;voluer et peuvent faire l&rsquo;objet d&rsquo;une actualisation de la part de l&rsquo;Editeur, notamment une mise &agrave; jour des Contenus.&nbsp;</p>

        <h2 class="mb-4">ARTICLE 3 &ndash; DUR&Eacute;E</h2>

        <p>Les pr&eacute;sentes CGU entrent en vigueur &agrave; compter de l&rsquo;acc&egrave;s au Site et/ou aux Contenus du Site par l&rsquo;Utilisateur et demeureront en vigueur jusqu&rsquo;&agrave; l&rsquo;extinction des obligations respectives des Parties et/ou l&rsquo;arr&ecirc;t des Services.&nbsp;</p>

        <h2 class="mb-4">ARTICLE 4 &ndash; DESCRIPTION DES SERVICES ET ACCES AU SITE</h2>

        <p>
          <strong>4.1 Descriptions des Services&nbsp;</strong>
        </p>

        <p>L&rsquo;Editeur met &agrave; disposition des Utilisateurs via son Site des manuels scolaires num&eacute;riques interactifs &agrave; destination des coll&egrave;ges et lyc&eacute;es permettant de s&rsquo;adapter au niveau des &eacute;l&egrave;ves et de faciliter le suivi et la progression des &eacute;l&egrave;ves par les enseignants.</p>

        <p>Les Contenus et fonctionnalit&eacute;s accessibles d&eacute;pendent du profil de l&rsquo;Utilisateur (enseignant, &eacute;l&egrave;ve, parent) et de ses droits d&rsquo;acc&egrave;s.</p>

        <p>Les Utilisateurs reconnaissent que les Services ont vocation &agrave; &ecirc;tre utilis&eacute;s uniquement &agrave; des fins &eacute;ducatives et p&eacute;dagogiques.&nbsp;</p>

        <p>
          <strong>4.2 Acc&egrave;s au Site et aux Contenus&nbsp;</strong>
        </p>

        <p>
          Le Site est accessible en ligne via un navigateur Internet, &agrave; l&rsquo;adresse suivante :
          <a
            href="http://www.lecrandusavoir.fr"
          >www.lecrandusavoir.fr</a>.
        </p>

        <p>Les Utilisateurs peuvent acc&eacute;der au Site 24 heures sur 24, 7 jours sur 7, sauf cas de force majeure ou d&rsquo;&eacute;v&egrave;nement hors du contr&ocirc;le de l&rsquo;Editeur, incidents &eacute;ventuels et sous r&eacute;serve d&rsquo;interventions de maintenance et de mise &agrave; jour.</p>

        <p>Il existe diff&eacute;rentes mani&egrave;res d&rsquo;acc&eacute;der aux Contenus du Site&nbsp;:</p>

        <ul>
          <li>Soit &agrave; partir de la &laquo;&nbsp;home page&nbsp;&raquo; du Site&nbsp;</li>
          <li>
            Soit &agrave; partir du site du Gestionnaire d&rsquo;Acc&egrave;s aux Ressources num&eacute;riques (GAR) du Minist&egrave;re de l&rsquo;&eacute;ducation nationale&nbsp;:
            <a
              href="https://gar.education.fr/"
            >https://gar.education.fr/</a>&nbsp;
          </li>
          <li>Soit, notamment pour les Utilisateurs &eacute;l&egrave;ves, directement &agrave; partir d&rsquo;un lien d&rsquo;acc&egrave;s envoy&eacute; par son enseignant</li>
        </ul>

        <p>Quel que soit le mode d&rsquo;acc&egrave;s, l&rsquo;Utilisateur doit s&rsquo;identifier &agrave; partir d&rsquo;un code d&rsquo;acc&egrave;s et d&rsquo;un mot de passe.&nbsp;</p>

        <p>Le code d&rsquo;acc&egrave;s et le mot de passe sont remis &agrave; l&rsquo;Utilisateur enseignant directement par l&rsquo;Editeur apr&egrave;s la passation de la Commande, ou par le GAR ou le libraire adjudicataire lorsque la Commande n&rsquo;est pas intervenue directement via le Site.</p>

        <p>L&rsquo;Utilisateur enseignant se charge ensuite de remettre &agrave; chacun de ses &eacute;l&egrave;ves ses codes d&rsquo;acc&egrave;s et mots de passe.&nbsp;</p>

        <p>Les Utilisateurs s&rsquo;engagent &agrave; ne pas permettre l&rsquo;acc&egrave;s au Site et aux Contenus &agrave; des tiers ne disposant pas d&rsquo;un droit d&rsquo;acc&egrave;s personnel au Site. L&rsquo;identifiant et le mot de passe de chacun des Utilisateurs sont consid&eacute;r&eacute;s comme des informations confidentielles.</p>

        <p>
          <strong>4.2 Acc&egrave;s au Tableau de bord</strong>
        </p>

        <p>Seuls les Utilisateurs enseignants peuvent, &agrave; partir de leur identifiant et mot de passe, acc&eacute;der au Tableau de bord en se connectant directement &agrave; partir de l&rsquo;onglet d&eacute;di&eacute; sur le Site.&nbsp;</p>

        <p>L&rsquo;Utilisateur enseignant b&eacute;n&eacute;ficiera ainsi de fonctionnalit&eacute;s suppl&eacute;mentaires et pourra notamment&nbsp;:&nbsp;</p>

        <ul>
          <li>Naviguer sur l&rsquo;ensemble des Contenus command&eacute;s</li>
          <li>Interagir avec ses &eacute;l&egrave;ves,&nbsp;</li>
          <li>Adapter les exercices au niveau de chaque &eacute;l&egrave;ve,</li>
          <li>Noter les &eacute;l&egrave;ves,&nbsp;</li>
          <li>Moduler les Contenus en choisissant par exemple les documents &agrave; &eacute;tudier et les exercices qui devront &ecirc;tre r&eacute;alis&eacute;s,</li>
          <li>Modifier, annoter et/ou supprimer, avec l&rsquo;accord des auteurs, certains Contenus, sous r&eacute;serve de ne pas d&eacute;naturer lesdits Contenus,</li>
          <li>De mani&egrave;re g&eacute;n&eacute;rale, g&eacute;rer son compte.</li>
        </ul>

        <h2 class="mb-4">ARTICLE 5 &ndash; MODALITES DE PASSATION DE COMMANDE</h2>

        <p>Afin que les Utilisateurs puissent b&eacute;n&eacute;ficier des Services propos&eacute;s par l&rsquo;Editeur, l&rsquo;Acheteur doit pr&eacute;alablement passer une Commande, selon diff&eacute;rentes modalit&eacute;s&nbsp;:&nbsp;</p>

        <ol>
          <li>Soit par l&rsquo;interm&eacute;diaire du GAR, lorsqu&rsquo;il est obligatoire. Les conditions et modalit&eacute;s de la commande seront alors encadr&eacute;es par les stipulations contractuelles du GAR.&nbsp;</li>
          <li>Soit par l&rsquo;interm&eacute;diaire d&rsquo;un libraire adjudicateur. Les conditions et modalit&eacute;s de la commande seront alors encadr&eacute;es par les stipulations contractuelles du libraire adjudicateur.&nbsp;</li>
          <li>Soit via le Site, selon les conditions et modalit&eacute;s ci-apr&egrave;s.&nbsp;</li>
        </ol>

        <p></p>
        <p>
          <strong>5.1 Passation d&rsquo;une Commande via le Site&nbsp;</strong>
        </p>

        <p>
          Dans cette hypoth&egrave;se, l&rsquo;Acheteur pourra demander un devis et passer commande envoyant un email &agrave; l&rsquo;adresse
          <a
            href="mailto:commande@lecrandusavoir.fr"
          >commande@lecrandusavoir.fr</a>.&nbsp;
        </p>

        <p>
          <strong>5.2 Prix&nbsp;</strong>
        </p>

        <p>Le Prix est exprim&eacute; en euro, hors taxes et toutes taxes comprises dans le r&eacute;capitulatif de la Commande avant la validation ou dans le devis communiqu&eacute; &agrave; l&rsquo;Acheteur par email.&nbsp;</p>

        <p>L&rsquo;Editeur se r&eacute;serve le droit de modifier le prix en cas de nouvelles demandes de l&rsquo;Acheteur ou de modification de la Commande.&nbsp;</p>

        <p>Le total hors taxes, le montant de la TVA et son taux, le total toutes taxes comprises sont mentionn&eacute;s dans le r&eacute;capitulatif de la Commande ou sur le devis.</p>

        <h2 class="mb-4">ARTICLE 6 &ndash; OBLIGATIONS DES PARTIES</h2>

        <p>Pour les besoins des pr&eacute;sentes, les Parties reconnaissent l&rsquo;importance de leur collaboration pour permettre &agrave; l&rsquo;Editeur de fournir les Services.&nbsp;</p>

        <p>
          <strong>6.1 Obligations de l&rsquo;Editeur</strong>
        </p>

        <p>L&rsquo;Editeur s&rsquo;engage &agrave; ex&eacute;cuter les CGU et notamment fournir les Services, avec tout le soin et la diligence raisonnablement attendus d&rsquo;un professionnel diligent, sp&eacute;cialiste du domaine concern&eacute;. L&rsquo;Editeur s&rsquo;engage &agrave; fournir les Services avec les moyens humains et mat&eacute;riels n&eacute;cessaires.</p>

        <p>Les Contenus accessibles via le Site sont fournis &agrave; titre informatif. Ils sont susceptibles d&rsquo;erreur, d&rsquo;omission, d&rsquo;obsolescence. L&rsquo;Utilisateur reconna&icirc;t en cons&eacute;quence interpr&eacute;ter et/ou utiliser ces Contenus &agrave; ses seuls risques et p&eacute;rils et il reconnait &ecirc;tre seul responsable de l&rsquo;usage qu&rsquo;il fait des Contenus et des Services. L&rsquo;Editeur ne saurait &ecirc;tre tenu pour responsable de dommages directs ou indirects d&eacute;coulant de leur utilisation ou interpr&eacute;tation.&nbsp;</p>

        <p>L&#39;Editeur fera ses meilleurs efforts pour que le Site et les Contenus soient accessibles &agrave; tout moment par l&rsquo;Utilisateur via le r&eacute;seau Internet. Toutefois, l&rsquo;acc&egrave;s aux Sites et aux Contenus pourra &ecirc;tre suspendu, pour une p&eacute;riode qui sera dans la mesure du possible la plus courte possible, en cas d&rsquo;op&eacute;rations de maintenance technique n&eacute;cessaires &agrave; la mise &agrave; jour du Site, son &eacute;volution et/ou la correction de bugs, sans que la responsabilit&eacute; de l&rsquo;Editeur ne puisse &ecirc;tre engag&eacute;e &agrave; ce titre et n&rsquo;ouvrant par voie de cons&eacute;quence droit &agrave; aucune indemnisation de l&rsquo;Utilisateur pour la g&ecirc;ne occasionn&eacute;e. Les interventions de maintenance pourront &ecirc;tre effectu&eacute;es sans que les Utilisateurs aient &eacute;t&eacute; pr&eacute;alablement avertis.</p>

        <p>L&rsquo;acc&egrave;s au Site et aux Contenus pourra &eacute;galement &ecirc;tre suspendu en cas de force majeure ou de survenance d&#39;un &eacute;v&egrave;nement hors du contr&ocirc;le de l&rsquo;Editeur.&nbsp;</p>

        <p>De mani&egrave;re g&eacute;n&eacute;rale, la responsabilit&eacute; de l&#39;Editeur ne peut &ecirc;tre engag&eacute;e en cas de d&eacute;faillance, panne, difficult&eacute; ou interruption de fonctionnement emp&ecirc;chant l&rsquo;acc&egrave;s au Site.</p>

        <p>Enfin, l&rsquo;Editeur ne pourra &ecirc;tre tenu pour responsable en cas de dommage cons&eacute;cutif &agrave; l&rsquo;usage des Services en contravention des pr&eacute;sentes CGU ou au non-respect, notamment par n&eacute;gligence, des r&egrave;gles &eacute;dict&eacute;es au sein des CGU en mati&egrave;re de s&eacute;curit&eacute; (&eacute;quipement mais &eacute;galement identifiant et mot de passe).</p>

        <p>
          <strong>6.2 Obligations de l&rsquo;Utilisateur</strong>
        </p>

        <p>L&rsquo;Utilisateur s&rsquo;engage &agrave; respecter les pr&eacute;sentes CGU et utiliser les Services &agrave; des fins p&eacute;dagogiques, &eacute;ducatives et dans un cadre scolaire.&nbsp;</p>

        <p>En particulier, l&rsquo;Utilisateur s&rsquo;engage &agrave; :</p>

        <p>- disposer de l&rsquo;environnement et de l&rsquo;&eacute;quipement technique n&eacute;cessaire &agrave; l&#39;acc&egrave;s au Site et au bon fonctionnement des Services (anti-virus, syst&egrave;mes d&rsquo;exploitation et navigateurs compatibles&hellip;) afin d&#39;&eacute;viter des cons&eacute;quences dommageables telles que ralentissements, blocages&nbsp;;</p>

        <p>- assumer l&rsquo;enti&egrave;re responsabilit&eacute; de l&rsquo;exactitude des informations qu&rsquo;il transmet &agrave; l&#39;Editeur dans le cadre des pr&eacute;sentes CGU, et les mettre &agrave; jour si n&eacute;cessaire&nbsp;;</p>

        <p>- ne pas mettre les Contenus &agrave; la disposition d&rsquo;un tiers non autoris&eacute;s et en dehors de finalit&eacute;s p&eacute;dagogiques et &eacute;ducatives ;</p>

        <p>- ne pas publier tout ou partie des Contenus mis &agrave; disposition par l&rsquo;Editeur sur des sites personnels ou sur quelque site que ce soit&nbsp;;</p>

        <p>- ne pas alt&eacute;rer ou perturber l&rsquo;int&eacute;grit&eacute; ou l&rsquo;ex&eacute;cution du Site ;</p>

        <p>&nbsp;- ne pas tenter d&rsquo;obtenir un acc&egrave;s non autoris&eacute; au Site et aux contenus ou aux syst&egrave;mes ou r&eacute;seaux qui sont associ&eacute;s&nbsp;;</p>

        <p>- signaler tout Contenu obsol&egrave;te, litigieux, illicite ou susceptible de porter atteinte aux droits des personnes, &agrave; l&rsquo;ordre public ou aux bonnes m&oelig;urs&nbsp;;</p>

        <p>- respecter les droits de propri&eacute;t&eacute; intellectuelle de l&#39;Editeur et de tout auteur ;</p>

        <p>- respecter toute disposition l&eacute;gale, r&eacute;glementaire et contractuelle qui lui est applicable,</p>

        <p>- ne pas utiliser les Services dans des conditions de nature &agrave; porter atteinte au bon fonctionnement ou &agrave; la s&eacute;curit&eacute; du Site.</p>

        <p>
          <strong>6.3 Obligations de l&rsquo;Acheteur</strong>
        </p>

        <p>L&rsquo;Acheteur s&rsquo;engage &agrave; respecter les pr&eacute;sentes CGU et &agrave; passer Commande pour le compte des Utilisateurs uniquement &agrave; des fins p&eacute;dagogiques, &eacute;ducatives et dans un cadre scolaire.&nbsp;</p>

        <p>En particulier, l&rsquo;Acheteur s&rsquo;engage &agrave; :</p>

        <p>- disposer d&rsquo;une connexion internet n&eacute;cessaire &agrave; la passation d&rsquo;une Commande via le Site ;</p>

        <p>- assumer l&rsquo;enti&egrave;re responsabilit&eacute; de l&rsquo;exactitude des informations qu&rsquo;il transmet &agrave; l&#39;Editeur dans le cadre de la passation d&rsquo;une Commande, et les mettre &agrave; jour si n&eacute;cessaire&nbsp;;</p>

        <p>- ne pas mettre le Site &agrave; la disposition d&rsquo;un tiers non autoris&eacute;s et en dehors de finalit&eacute;s p&eacute;dagogiques et &eacute;ducatives ;</p>

        <p>- ne pas alt&eacute;rer ou perturber l&rsquo;int&eacute;grit&eacute; ou l&rsquo;ex&eacute;cution du Site ;</p>

        <p>&nbsp;- ne pas tenter d&rsquo;obtenir un acc&egrave;s non autoris&eacute; au Site ou aux syst&egrave;mes ou r&eacute;seaux qui sont associ&eacute;s&nbsp;;</p>

        <p>- r&eacute;gler le prix des Services souscrits via le Site ;&nbsp;</p>

        <p>- respecter toute disposition l&eacute;gale, r&eacute;glementaire et contractuelle qui lui est applicable.&nbsp;</p>

        <h2 class="mb-4">ARTICLE 7 - PROPRIETE INTELLECTUELLE&nbsp;</h2>

        <p>Le Site et chacun de ses &eacute;l&eacute;ments, y compris marques, logos, noms de domaine, la structure g&eacute;n&eacute;rale, les logiciels, algorithmes, ainsi que tous les Contenus sont la propri&eacute;t&eacute; exclusive de l&rsquo;Editeur ou fait l&rsquo;objet d&rsquo;une autorisation d&rsquo;exploitation dont l&rsquo;Editeur est seul b&eacute;n&eacute;ficiaire.</p>

        <p>L&rsquo;acc&egrave;s au Site n&rsquo;emporte aucune cession de droit de propri&eacute;t&eacute; intellectuelle sur les &eacute;l&eacute;ments composant le Site et les Contenus au profit des Utilisateurs ou de l&rsquo;Acheteur. Seul un droit d&rsquo;utilisation du Site et des Contenus est conc&eacute;d&eacute;s &agrave; des fins p&eacute;dagogiques et &eacute;ducatives.</p>

        <p>Le Site et ses Contenus sont prot&eacute;g&eacute;s, pour le monde entier, par le droit d&#39;auteur, les droits de propri&eacute;t&eacute; industrielle dont l&rsquo;Editeur est titulaire et, le cas &eacute;ch&eacute;ant, par le droit prot&eacute;geant les bases de donn&eacute;es dont l&rsquo;Editeur et/ou ses contributeurs sont les producteurs. Le Site ainsi que ses Contenus ne peuvent donc en aucune mani&egrave;re faire l&#39;objet, m&ecirc;me partiellement, de reproduction, repr&eacute;sentation, pr&ecirc;t, &eacute;change ou cession, d&rsquo;extraction totale ou partielle de donn&eacute;es et/ou d&rsquo;un transfert sur un autre support, de modification (sauf dans le cas particulier des enseignants pr&eacute;vu &agrave; l&rsquo;article 4.2 des pr&eacute;sentes), de traduction, arrangement ou transformation.</p>

        <p>Toute exploitation non autoris&eacute;e du Site et des Contenus et/ou de ses contenus sera consid&eacute;r&eacute;e comme constitutive d&rsquo;un acte de contrefa&ccedil;on sanctionn&eacute; par les dispositions du Code de Propri&eacute;t&eacute; Intellectuelle.</p>

        <h2 class="mb-4">ARTICLE 8 &ndash; DONNEES PERSONNELLES</h2>

        <p>En application de la loi n&deg;78-17 du 6 janvier 1978 modifi&eacute;e et du R&egrave;glement europ&eacute;en n&deg;2019/679 relatif &agrave; la protection des donn&eacute;es du 27 avril 2016 (&laquo; RGPD &raquo;), l&rsquo;Editeur traite, via son Site, des Donn&eacute;es Personnelles des Utilisateurs, conform&eacute;ment &agrave; la l&eacute;gislation en vigueur et dans les conditions d&eacute;taill&eacute;es au sein de sa Politique de confidentialit&eacute;, facilement accessible sur le Site internet et &agrave; l&rsquo;adresse : (lien hypertexte vers politique de confidentialit&eacute;).</p>

        <h2 class="mb-4">ARTICLE 9 &ndash; RESILIATION&nbsp;</h2>

        <p>Les pr&eacute;sentes CGU pourront &ecirc;tre r&eacute;sili&eacute;es de plein droit par l&rsquo;Editeur en cas de non-respect par l&rsquo;Utilisateur ou l&rsquo;Acheteur de l&rsquo;une de ses obligations issues des CGU.</p>

        <p>Cette r&eacute;siliation prendra effet d&egrave;s sa notification par l&rsquo;Editeur par lettre recommand&eacute;e avec accus&eacute; de r&eacute;ception, sans mise en demeure pr&eacute;alable, la simple constatation de l&rsquo;inex&eacute;cution de l&rsquo;une de ces obligations &eacute;tant suffisante pour permettre &agrave; l&rsquo;Editeur de r&eacute;silier les CGU.</p>

        <p>Dans une telle hypoth&egrave;se, l&rsquo;acc&egrave;s au Tableau de Bord, au Site et aux Contenus lui sera refus&eacute; et sera cl&ocirc;tur&eacute;.</p>

        <p>Toute r&eacute;siliation, aux torts exclusifs de l&rsquo;Utilisateur ou de l&rsquo;Acheteur, n&rsquo;entra&icirc;nera aucun d&eacute;dommagement et/ou remboursement au b&eacute;n&eacute;fice de ce dernier, sans pr&eacute;judice de tous dommages et int&eacute;r&ecirc;ts auxquels l&rsquo;Editeur pourrait pr&eacute;tendre.</p>

        <h2 class="mb-4">ARTICLE 10 &ndash; FORCE MAJEURE</h2>

        <p>Les Parties ne pourront &ecirc;tre tenues responsables pour un manquement &agrave; l&rsquo;une des obligations mises &agrave; leur charge par les CGU qui r&eacute;sulterait de la survenance d&rsquo;un cas de force majeure, tels que ceux habituellement retenus par la loi et la jurisprudence fran&ccedil;aises.</p>

        <p>La Partie invoquant un cas de force majeure doit notifier son existence &agrave; l&rsquo;autre Partie d&egrave;s que possible, faire de son mieux pour en limiter les cons&eacute;quences et reprendre l&rsquo;ex&eacute;cution de ses obligations imm&eacute;diatement apr&egrave;s que ce cas de force majeure ait disparu.</p>

        <p>Si le cas de force majeure subsistait plus d&rsquo;un (1) mois, les CGU pourront &ecirc;tre r&eacute;sili&eacute;es imm&eacute;diatement, de plein droit, sans indemnit&eacute; de part et d&rsquo;autre, par l&rsquo;une quelconque des Parties, par notification &eacute;crite adress&eacute;e &agrave; l&rsquo;autre Partie.</p>

        <h2 class="mb-4">ARTICLE 11 &ndash; Droit applicable et comp&eacute;tence&nbsp;</h2>

        <p>Les pr&eacute;sentes CGU sont soumises &agrave; la loi fran&ccedil;aise.&nbsp;</p>

        <p>Tout litige relatif au CGU r&eacute;sultant notamment de sa signature, de son ex&eacute;cution, de son interpr&eacute;tation, de sa r&eacute;siliation, ou de sa validit&eacute;, et qui n&#39;aurait pu &ecirc;tre r&eacute;solu &agrave; l&rsquo;amiable entre les Parties sera soumis aux tribunaux comp&eacute;tents dans les conditions de droit commun.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "About",
  title: "L'écran du savoir - Conditions générales d'utilisation",
  data: () => ({}),
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>