function getTitle(vm) {
    const {
        title
    } = vm.$options
    if (title) {
        return typeof title === 'function' ?
            title.call(vm) :
            title
    }
}

function getDescription(vm) {
    const {
        description
    } = vm.$options
    if (description) {
        return typeof description === 'function' ?
            description.call(vm) :
            description
    }
}

export default {
    created() {
        const title = getTitle(this)
        if (title) {
            document.title = title
        }
        const description = getDescription(this)
        if (description) {
            document.querySelector('meta[name="description"]').setAttribute("content", description);
        }
    }
}