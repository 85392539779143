<template>
  <v-dialog persistent v-model="dialog_newsletter" max-width="360">
    <v-card>
      <v-card-title class="modal-title"
        >Inscrivez-vous pour tester le manuel avec vos classes</v-card-title
      >
      <v-card-text class="modal-text">
        <p>
          Durant trois mois bénéficiez des fonctionnalités d'échange d'exercices
          et de personnalisation du manuel.
        </p>
        <p>Restez informé des évolutions de nos manuels scolaires.</p>
      </v-card-text>
      <div class="form-modal">
        <v-text-field
          name="email"
          v-model="user_email"
          dense
          label="Votre e-mail"
          placeholder="Votre e-mail"
          outlined
        ></v-text-field>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="#ec446d" text @click="closeModalNewsletter()"
          >Non merci</v-btn
        >
        <v-btn color="#03B2BF" dark @click="registerNewsletter('inscription')"
          >Je m'inscris</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "Btn_teaser",
  props: ["dialog_newsletter"],
  data: () => ({
    user_email: "",
  }),
  methods: {
    closeModalNewsletter() {
      this.$emit("closeModalNewsletter");
    },
    registerNewsletter(source) {
      if (this.user_email != "") {
        const payload = {
          email: this.user_email,
          source: source,
        };
        axios
          .post(
            "https://apis.lecrandusavoir.fr/textbook/api/store_newsletter_registration",
            payload
          )
          .then(() => {
            this.closeModalNewsletter();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style></style>
