<template>
  <v-app>
    <v-app-bar app color="#154f97" dark>

      <router-link to="/">
        <v-toolbar-title class="toolbar-title"
          >L'écran du savoir</v-toolbar-title
        >
      </router-link>
      <v-spacer></v-spacer>
      <v-menu
        open-on-hover
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            bottom
            text
            v-on="on"
            class="d-none d-sm-flex"
          >
            Manuels
          </v-btn>
        </template>

        <v-list>
          <router-link to="/manuel/histoire_seconde">
            <v-list-item>
              <v-list-item-title><v-btn class="d-none d-sm-flex" text>Histoire seconde</v-btn></v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link to="/manuel/geographie_seconde">
            <v-list-item>
              <v-list-item-title><v-btn class="d-none d-sm-flex" text>Géographie seconde</v-btn></v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link to="/manuel/histoire_geographie_seconde">
            <v-list-item>
              <v-list-item-title><v-btn class="d-none d-sm-flex" text>Histoire & Géographie seconde</v-btn></v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link to="/manuel/histoire_premiere">
            <v-list-item>
              <v-list-item-title><v-btn class="d-none d-sm-flex" text>Histoire première</v-btn></v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link to="/manuel/geographie_premiere">
            <v-list-item>
              <v-list-item-title><v-btn class="d-none d-sm-flex" text>Géographie première</v-btn></v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link to="/manuel/histoire_geographie_premiere">
            <v-list-item>
              <v-list-item-title><v-btn class="d-none d-sm-flex" text>Histoire & Géographie première</v-btn></v-list-item-title>
            </v-list-item>
          </router-link>

        </v-list>
      </v-menu>
      <router-link to="/blog">
        <v-btn class="d-none d-sm-flex" text>Blog</v-btn>
      </router-link>
      <router-link to="/qui-sommes-nous">
        <v-btn class="d-none d-sm-flex" text>Qui sommes-nous</v-btn>
      </router-link>
      <v-btn
        target="_blank"
        href="mailto:contact@lecrandusavoir.fr"
        class="d-none d-sm-flex"
        text
        >Contact</v-btn
      >
      <v-btn
        target="_blank"
        href="https://forms.gle/DQcpag1ggJwA4LB38"
        class="d-none d-sm-flex"
        text
        >Recevoir un spécimen</v-btn
      >
    </v-app-bar>
    <v-main>
      <router-view v-slot="{ Component }">
        <transition name="route.meta.transition || 'fade'">
          <component :is="Component" />
        </transition>
      </router-view>
      <Footer></Footer>
    </v-main>
    <modal-newsletter
      :dialog_newsletter="dialog_newsletter"
      @closeModalNewsletter="closeModalNewsletter"
    ></modal-newsletter>
  </v-app>
</template>

<script>
import Footer from "./components/Footer.vue";
import ModalNewsletter from "./components/ModalNewsletter.vue";

export default {
  name: "App",
  components: {
    Footer,
    ModalNewsletter,
  },
  data: () => ({
    dialog_newsletter: false,
    user_email: null,
    manuelPages:[
      {
        id: 113,
        title:'Histoire Seconde',
        slug: 'histoire_seconde'
      },
      {
        id: 111,
        title:'Géographie Seconde',
        slug: 'geographie_seconde'
      },
      {
        id: 108,
        title:'Histoire Géographie Seconde',
        slug: 'histoire_geographie_seconde'
      }
    ]
  }),
  mounted() {
    this.route_name = this.$route.name;
  },
  methods: {
    openModalNewsletter() {
      this.dialog_newsletter = true;
    },
    closeModalNewsletter() {
      this.dialog_newsletter = false;
    },
  },
};
</script>
<style>
.appbar {
  width: 100%;
}
.title {
  font-size: 30px;
  text-transform: uppercase;
  color: white !important;
  text-decoration: none;
}

.cursor {
  cursor: pointer;
}
</style>
