<template>
  <v-container class="main-container">
    <v-row>
      <v-col cols="12">
        <h1 class="text-center mt-2 mb-8">POLITIQUE DE CONFIDENTIALITÉ</h1>
        <p>
          Vous pouvez naviguer et visiter le site internet
          <a
            href="https://www.lecrandusavoir.fr/"
          >https://www.lecrandusavoir.fr/</a> (ci-apr&egrave;s le &laquo;&nbsp;Site&nbsp;&raquo;) sans avoir &agrave; fournir d&rsquo;informations personnelles. La collecte et le traitement de donn&eacute;es personnelles sont effectu&eacute;s dans le cadre de la souscription aux services (encadr&eacute;e par les CGU), et/ou via l&rsquo;utilisation du formulaire de contact accessible depuis la page &laquo;&nbsp;Nous contacter&nbsp;&raquo; sur le Site.&nbsp;
        </p>

        <p>En tout &eacute;tat de cause PARTENAIRE DU SAVOIR s&rsquo;engage, en sa qualit&eacute; de responsable de traitement, &agrave; traiter vos donn&eacute;es personnelles conform&eacute;ment &agrave; la loi n&deg;78-17 du 6 janvier 1978 modifi&eacute;e et du R&egrave;glement europ&eacute;en n&deg;2019/679 relatif &agrave; la protection des donn&eacute;es du 27 avril 2016 (&laquo; RGPD &raquo;) et &agrave; garantir leur confidentialit&eacute;.</p>

        <p>
          <strong>Quels sont les donn&eacute;es personnelles que nous collectons&nbsp;?&nbsp;</strong>
        </p>

        <p>Dans le cadre de la souscription aux services et notamment de l&rsquo;acc&egrave;s &agrave; nos contenus, sont collect&eacute;es directement ou indirectement&nbsp;:&nbsp;</p>

        <ul>
          <li>Des donn&eacute;es d&rsquo;identification&nbsp;: civilit&eacute;, nom, pr&eacute;nom, statut (&eacute;l&egrave;ve, enseignant, parent d&rsquo;&eacute;l&egrave;ve, directeur d&rsquo;&eacute;tablissement scolaire) et plus particuli&egrave;rement concernant les enseignants&nbsp;: profession, discipline, niveau enseign&eacute;, &eacute;tablissement d&rsquo;enseignement, etc.</li>
          <li>Des donn&eacute;es de contact&nbsp;: email, n&deg; de t&eacute;l&eacute;phone, nom et adresse de l&rsquo;&eacute;tablissement scolaire&hellip;</li>
          <li>Des informations scolaires relatives aux &eacute;l&egrave;ves&nbsp;: notes, r&eacute;sultats des exercices, niveau scolaire, progression, etc.&nbsp;</li>
          <li>Le cas &eacute;ch&eacute;ant des donn&eacute;es li&eacute;es aux commandes effectu&eacute;es&nbsp;: historique, contenus command&eacute;s, prix, donn&eacute;es bancaires&hellip;</li>
          <li>Des donn&eacute;es de connexion&nbsp;et de navigation sur le Site</li>
        </ul>

        <p>Dans le cadre de l&rsquo;utilisation du formulaire de contact sur le Site, seuls le nom, pr&eacute;nom, email et objet de la demande sont collect&eacute;s.&nbsp;</p>

        <p>
          <strong>Pourquoi vos donn&eacute;es personnelles sont-elles recueillies et trait&eacute;es et dans quel cadre ?</strong>
        </p>

        <p>En application de la loi n&deg;78-17 du 6 janvier 1978 modifi&eacute;e et du RGPD, il est rappel&eacute; que les donn&eacute;es &agrave; caract&egrave;re personnel que vous renseignez via le Site ou lors de la souscription aux Services sont trait&eacute;es par PARTENAIRE DU SAVOIR pour l&rsquo;une ou l&rsquo;autre des finalit&eacute;s suivantes:</p>

        <ul>
          <li>la mise &agrave; disposition du Site, de ses contenus et la fourniture des services aux utilisateurs,</li>
          <li>la gestion et le traitement des commandes, de la facturation, des paiements,</li>
          <li>la gestion des &eacute;ventuels litiges et r&eacute;clamations,</li>
          <li>l&rsquo;envoi de newsletters et/ou la diffusion d&rsquo;informations promotionnelles en lien avec les services et contenus command&eacute;s,&nbsp;</li>
          <li>La recommandation de contenus adapt&eacute;s &agrave; l&rsquo;&eacute;l&egrave;ve&nbsp;</li>
          <li>la gestion des demandes d&rsquo;informations via le formulaire de contact,</li>
          <li>les mesures d&rsquo;audience du Site et statistiques afin d&rsquo;am&eacute;liorer la navigation sur le Site.</li>
        </ul>

        <p>Ces donn&eacute;es personnelles ne seront trait&eacute;es ou utilis&eacute;es que i) dans la mesure o&ugrave; cela est strictement n&eacute;cessaire pour les finalit&eacute;s vis&eacute;es ci-dessus, ii) et dans la mesure o&ugrave; cela est n&eacute;cessaire pour l&rsquo;ex&eacute;cution des services dans le cadre des CGU, iii) ou dans l&rsquo;int&eacute;r&ecirc;t l&eacute;gitime de PARTENAIRE DU SAVOIR, iv) ou pour r&eacute;pondre &agrave; une obligation l&eacute;gale ou r&eacute;glementaire.&nbsp;</p>

        <p>
          <strong>Qui sont les destinataires de vos donn&eacute;es&nbsp;personnelles ?</strong>
        </p>

        <p>PARTENAIRE DU SAVOIR ne commercialise pas vos donn&eacute;es personnelles &agrave; des tiers. Les donn&eacute;es &agrave; caract&egrave;re personnel collect&eacute;es et/ou produites sont exclusivement destin&eacute;es &agrave;&nbsp;:&nbsp;</p>

        <ul>
          <li>PARTENAIRE DU SAVOIR, ses &eacute;ventuelles soci&eacute;t&eacute;s affili&eacute;es et son personnel habilit&eacute;,</li>
          <li>Ses sous-traitants auxquels PARTENAIRE DU SAVOIR fait appel par contrat pour l&rsquo;ex&eacute;cution de prestations ou services techniques n&eacute;cessaires. Ces sous-traitants s&rsquo;engagent contractuellement envers PARTENAIRE DU SAVOIR &agrave; garantir un niveau de s&eacute;curit&eacute; et de confidentialit&eacute; de vos donn&eacute;es personnelles dans des conditions conformes &agrave; la r&egrave;glementation applicable et notamment au RGPD.</li>
          <li>&eacute;ventuellement des auxiliaires de justice, les organes judiciaires et officiers minist&eacute;riels en cas de contentieux, notamment en cas de recouvrement de cr&eacute;ances.&nbsp;</li>
        </ul>

        <p>Aucun transfert de donn&eacute;es hors de l&#39;Union europ&eacute;enne n&#39;est r&eacute;alis&eacute;.&nbsp;</p>

        <p>
          <strong>Pendant combien de temps conservons-nous vos donn&eacute;es personnelles&nbsp;?</strong>
        </p>

        <p>Toutes les donn&eacute;es personnelles collect&eacute;es sont conserv&eacute;es pour une dur&eacute;e limit&eacute;e, en fonction de la finalit&eacute; du traitement et de la dur&eacute;e de conservation pr&eacute;vue par la l&eacute;gislation en vigueur.&nbsp;</p>

        <p>Dans le cadre de l&rsquo;utilisation des services, de la gestion des commandes et de l&rsquo;acc&egrave;s aux contenus, les donn&eacute;es sont conserv&eacute;es tant que le compte est actif et pendant 5 ans apr&egrave;s la r&eacute;siliation.</p>

        <p>Pour l&rsquo;envoi de newsletter ou informations promotionnelles, l&rsquo;adresse email est conserv&eacute;e tant que l&rsquo;utilisateur ne manifeste pas la volont&eacute; de se d&eacute;sinscrire &agrave; partir des liens de d&eacute;sabonnement figurant dans le pied de page de ces newsletters.</p>

        <p>Quant aux donn&eacute;es collect&eacute;es dans le cadre de l&rsquo;utilisation du formulaire de contact, elles peuvent &ecirc;tre conserv&eacute;es jusqu&rsquo;&agrave; 3 ans apr&egrave;s le dernier contact.</p>

        <p>
          <strong>Quelle est la s&eacute;curit&eacute; mise en &oelig;uvre&nbsp;?</strong>
        </p>

        <p>Le traitement de vos donn&eacute;es personnelles r&eacute;pond aux exigences l&eacute;gales et r&eacute;glementaires en mati&egrave;re de protection des donn&eacute;es personnelles. PARTENAIRE DU SAVOIR s&rsquo;engage &agrave; mettre en place des mesures techniques et organisationnelles appropri&eacute;es pour assurer la protection des donn&eacute;es et permettre aux utilisateurs l&rsquo;exercice de leurs droits. PARTENAIRE DU SAVOIR s&rsquo;engage notamment &agrave; prendre les pr&eacute;cautions utiles afin de pr&eacute;server la s&eacute;curit&eacute; et la confidentialit&eacute; des donn&eacute;es et notamment emp&ecirc;cher qu&rsquo;elles ne soient d&eacute;form&eacute;es, endommag&eacute;es ou communiqu&eacute;es &agrave; des personnes non autoris&eacute;es.</p>

        <p>
          <strong>Quels sont vos droits&nbsp;?</strong>
        </p>

        <p>Dans le cas o&ugrave; vous ne souhaiteriez pas/plus recevoir de newsletters et/ou d&rsquo;informations de la part de PARTENAIRE DU SAVOIR, vous avez la possibilit&eacute; de modifier votre choix en nous contactant dans les conditions &eacute;voqu&eacute;es ci-apr&egrave;s ou en utilisant les liens de d&eacute;sinscription pr&eacute;vus dans les courriers &eacute;lectroniques.</p>

        <p>Par ailleurs, vous disposez, conform&eacute;ment aux r&eacute;glementations nationales et europ&eacute;ennes en vigueur d&#39;un droit d&#39;acc&egrave;s, de rectification, d&rsquo;effacement, d&#39;opposition, de portabilit&eacute; et de limitation du traitement s&#39;agissant des informations vous concernant.</p>

        <p>Ces droits s&rsquo;exercent par courrier ou email&nbsp;:&nbsp;</p>

        <p>
          <strong>PARTENAIRE DU SAVOIR&nbsp;</strong>
        </p>

        <p>54 boulevard du Montparnasse, 75015 Paris.&nbsp;</p>

        <p>contact@lecrandusavoir.fr</p>

        <p>En cas de r&eacute;clamation, vous pouvez contacter la CNIL qui est l&rsquo;autorit&eacute; comp&eacute;tente en mati&egrave;re de protection des donn&eacute;es personnelles, dont les coordonn&eacute;es sont les suivantes : 3 Place de Fontenoy, 75007 Paris, t&eacute;l&eacute;phone : 01 53 73 22 22.</p>

        <p>
          <strong>Et les cookies&nbsp;?</strong>
        </p>

        <p>La navigation sur le Site est susceptible de conduire &agrave; l&rsquo;installation de cookies sur l&rsquo;ordinateur de l&rsquo;utilisateur. Un cookie est un fichier de petite taille, qui ne permet pas l&rsquo;identification de l&rsquo;utilisateur, mais qui enregistre des informations relatives &agrave; la navigation d&rsquo;un ordinateur sur un site internet. Les donn&eacute;es ainsi obtenues visent &agrave; faciliter la navigation et permettent au Site de fonctionner de mani&egrave;re optimale.</p>

        <p>Les cookies dits &laquo; cookies fonctionnels &raquo; sont automatiquement d&eacute;pos&eacute;s car ils sont n&eacute;cessaires au fonctionnement et &agrave; la s&eacute;curit&eacute; du Site. Ces cookies ne sont soumis &agrave; aucun consentement pr&eacute;alable.</p>

        <p>Par ailleurs,&nbsp;vous avez la possibilit&eacute; de vous opposer &agrave; l&rsquo;utilisation des autres types de cookies publicitaires ou de mesure d&rsquo;audience n&eacute;cessaires au fonctionnement et aux op&eacute;rations d&rsquo;administration courante du Site, lors de la consultation de notre Site, via le bandeau d&eacute;di&eacute; en bas du Site.&nbsp;</p>

        <p>Nos cookies ont une dur&eacute;e de vie limit&eacute;e ne d&eacute;passant pas 13 mois, en accord avec la l&eacute;gislation applicable, &agrave; l&rsquo;exception des cookies publicitaire ou de mesure d&rsquo;audience d&eacute;pos&eacute;s avec votre consentement qui sont conserv&eacute;s pour 6 mois.</p>

        <p>La collecte de cookies peut &ecirc;tre d&eacute;sactiv&eacute;e &eacute;galement &agrave; tous moment dans les pr&eacute;f&eacute;rences de votre navigateur. Si vous refusez tous les cookies, il est possible que certaines parties du Site ne puissent &ecirc;tre utilis&eacute;es dans les meilleures conditions de navigation.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "MentionsLegales",
  title: "L'écran du savoir - Politique de confidentialité",

  data: () => ({}),
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>