<template>
  <v-container class="wordpress_content">
    <v-row>
      <v-col cols="12">
        <h1 class="secondaire" v-html="manuel.title"></h1>
        <div v-html="manuel.content"></div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import axios from "axios";

export default {
  name: "App",
  data: () => ({
    manuel: {
      content: null,
      title: null
    },
    manuels: {
      'histoire_seconde': {
        idWordpress: 113
      },
      'geographie_seconde': {
        idWordpress: 111
      },
      'histoire_geographie_seconde': {
        idWordpress: 108
      },
      'histoire_premiere': {
        idWordpress: 172
      },
      'geographie_premiere': {
        idWordpress: 175
      },
      'histoire_geographie_premiere': {
        idWordpress: 178
      }
    }
  }),
  watch: {
    '$route.params': function () {
      this.init();
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.manuel = {
        content: null,
        title: null
      };

      this.loadManuelData();
    },
    loadManuelData() {
      let id = this.$route.params.id;

      if (this.manuels[id] === undefined) {
        this.$router.push('/');
        return;
      }

      const wordpressUrl = 'https://blog.lecrandusavoir.fr//wp-json/wp/v2/pages/' + this.manuels[id].idWordpress;
      axios.get(wordpressUrl)
        .then(response => {
          this.manuel.title = response.data.title.rendered;
          this.manuel.content = response.data.content.rendered;
        })
        .catch(error => {
          console.error('Erreur lors de la récupération du contenu WordPress :', error);
        });

    }
  },
};
</script>

<style>
.wordpress_content img {
  max-width: 600px;
}

.wordpress_content h1 {
  font-family: "wicked";
  color: #052C69;
  font-size: 36px;
}

.wordpress_content h3, .wordpress_content h2, .wordpress_content h4, .wordpress_content h5 {
  font-family: "Vollkorn", serif;
  color: #052C69;
  margin: 12px 0 0 0;
}

.wordpress_content h3 {
  font-size: 22px;
}

.wordpress_content h4 {
  font-size: 20px;
}

.wordpress_content h5 {
  font-size: 18px;
}

</style>