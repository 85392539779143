<template>
  <div class="std posts posts-list figure-diplayed">
    <v-container fluid>
      <v-container class="has-min-height">
        <v-row>
          <v-col cols="12" v-if="post">
            <h1>Les articles du Blog</h1>
            <div>
              <h2 v-html="post.title.rendered"></h2>
              <h3 v-html="post.content.rendered"></h3>
              <div class="centered-content mt-8">
                <v-btn
                  @click="badkToBlog()"
                  small
                  dark
                  depressed
                  color="#ec446d"
                >Retourner à la liste des articles</v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  title:
    "L'écran du savoir - Blog - Le premier manuel scolaire numérique et interactif qui s'adapte aux élèves",
  name: "Home",
  props: ["email_contact"],
  data: () => ({
    post: null,
  }),
  mounted() {
    window.scrollTo(0, 0);
    const post_id = this.$route.params.id;
    this.loadBlogPost(post_id);
  },
  methods: {
    badkToBlog() {
      this.$router.push("/blog/");
    },
    loadBlogPost(post_id) {
      axios
        .get("https://blog.lecrandusavoir.fr/wp-json/wp/v2/posts/" + post_id)
        .then((response) => {
          console.log(response.data);
          this.post = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
span {
  display: block;
}
</style>
