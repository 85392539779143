import VueRouter from 'vue-router';

import Home from './components/Home.vue';
import About from './components/About.vue';
import Blog from './components/Blog.vue';
import Article from './components/Article.vue';
import Manuel from "./components/Manuel.vue";

import Cgu from './components/Cgu.vue';
import MentionsLegales from './components/MentionsLegales.vue';
import PolitiqueDeConfidentialite from './components/PolitiqueDeConfidentialite.vue';

const router = new VueRouter({
  routes: [{
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      transition: 'slide-right'
    }
  }, {
    path: '/qui-sommes-nous',
    name: 'about',
    component: About,
    meta: {
      transition: 'slide-right'
    }
  }, {
    path: '/manuel/:id',
    name: 'manuel',
    component: Manuel,
    meta: {
      transition: 'slide-right'
    }
  }, {
    path: '/blog',
    name: 'blog',
    component: Blog,
    meta: {
      transition: 'slide-right'
    }
  }, {
    path: '/articles/:id',
    name: 'article',
    component: Article,
    meta: {
      transition: 'slide-right'
    }
  }, {
    path: '/cgu',
    name: 'cgu',
    component: Cgu,
    meta: {
      transition: 'slide-right'
    }
  }, {
    path: '/mentions-legales',
    name: 'mentions-legales',
    component: MentionsLegales,
    meta: {
      transition: 'slide-right'
    }
  }, {
    path: '/politique-de-confidentialite',
    name: 'politique-de-confidentialite',
    component: PolitiqueDeConfidentialite,
    meta: {
      transition: 'slide-right'
    }
  }],
  mode: 'history'
});



export default router;