<template>
  <div class="std posts posts-list">
    <v-container fluid>
      <v-container class="has-min-height">
        <v-row>
          <v-col cols="12" v-if="posts" class="blog-posts-list">
            <h1>Les articles du Blog</h1>
            <div
              v-for="(post, post_index) in posts"
              :key="post_index"
              class="mb-7"
            >
              <h2 @click="goToPost(post.id)" v-html="post.title.rendered"></h2>
              <h3 class="flex-container-article">
                <div v-if="post.jetpack_featured_media_url">
                  <img
                    :src="post.jetpack_featured_media_url"
                    :alt="post.title.rendered"
                  />
                </div>
                <p v-html="post.excerpt.rendered"></p>
              </h3>
              <div>
                <v-btn
                  @click="goToPost(post.id)"
                  small
                  dark
                  depressed
                  color="#ec446d"
                  >Lire la suite...</v-btn
                >
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  title:
    "L'écran du savoir - Blog - Le premier manuel scolaire numérique et interactif qui s'adapte aux élèves",
  name: "Home",
  props: ["email_contact"],
  data: () => ({
    posts: null,
  }),
  mounted() {
    window.scrollTo(0, 0);
    this.loadBlogPostsList();
  },
  methods: {
    goToPost(id) {
      this.$router.push("/articles/" + id);
    },
    loadBlogPostsList() {
      axios
        .get("https://blog.lecrandusavoir.fr/wp-json/wp/v2/posts")
        .then((response) => {
          console.log(response.data);
          this.posts = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
span {
  display: block;
}
</style>
