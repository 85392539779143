<template>
  <v-footer v-if="route_name != 'blog'" class="footer">
    {{ route_name }}
    <div class="container">
      <div class="footer-links">
        <router-link to="/blog">
          <span class="d-flex d-sm-none footer-links_policy">Blog</span>
        </router-link>
        <router-link to="/qui-sommes-nous">
          <span class="d-flex d-sm-none footer-links_policy"
            >Qui sommes-nous</span
          >
        </router-link>
        <a
          class="d-flex d-sm-none"
          aria-label="Contactez-nous"
          href="mailto:mailto:contact@lecrandusavoir.fr"
        >
          <span class="footer-links_policy">Contactez-nous</span>
        </a>
        <router-link to="/cgu">
          <span class="footer-links_policy"
            >Conditions générales d'utilisation</span
          >
        </router-link>
        <router-link to="/mentions-legales">
          <span class="footer-links_policy">Mentions légales</span>
        </router-link>
        <router-link to="/politique-de-confidentialite">
          <span class="footer-links_policy">Politique de confidentialité</span>
        </router-link>
        <a
          target="_blank"
          href="https://www.linkedin.com/company/85433405"
          class="ml-5 footer-links_policy"
        >
          <v-icon color="white">mdi-linkedin</v-icon
          ></a
        >

        <a
          target="_blank"
          href="https://x.com/ecrandusavoir?s=11&t=aooDmhrnL1WZDS3eyOR4RA"
          class="ml-5 footer-links_policy">
          <v-icon color="white">mdi-twitter</v-icon>
        </a>

        <a
          target="_blank"
          href="https://www.facebook.com/lecrandusavoir"
          class="ml-5 footer-links_policy">
          <v-icon color="white">mdi-facebook</v-icon>
        </a>
        <a
          target="_blank"
          href="https://www.youtube.com/@lecrandusavoir"
          class="ml-5 footer-links_policy">
          <v-icon color="white">mdi-youtube</v-icon>
        </a>

      </div>
    </div>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  props: ["email_contact", "route_name"],
  data: () => ({}),
};
</script>
