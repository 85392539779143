import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'
import router from './router';
import './assets/scss/style.scss';
import MetasMixin from "./components/MetasMixin";
import VueGtag from "vue-gtag";

Vue.use(VueRouter);
Vue.mixin(MetasMixin)

Vue.use(VueGtag, {
  config: {
    id: "G-M35CGNBCTK"
  }
});

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')