<template>
  <div class="std">
    <v-container fluid>
      <v-container>
        <v-row>
          <v-col cols="12">
            <h1>qui sommes-nous ?</h1>

            <h2 class="reduced mb-5">
              L'écran du Savoir est une maison d'édition de manuels scolaires
              numériques fondée en 2021 par Cécile TRIC, ex directrice
              éditoriale chez Hachette Education, rejointe par Pascal ROBBES, ex
              directeur technique. Ils partagent des valeurs telles que la
              transmission du savoir, l'innovation, la bienveillance,
              essentielles à ce projet.
            </h2>
            <h2 class="reduced mb-5">
                Nous sommes attachés à prendre en compte les remarques et conseils des enseignants qui utilisent
                nos manuels et prennent soin de nous remonter leurs besoins. Nous souhaitons que les élèves et leurs
                enseignants utilisent avec plaisir nos manuels.
            </h2>
            <div class="logo-large d-none">
              <img
                alt="l'écran du savoir"
                :src="require('../assets/pictures/logo-large.jpg')"
              />
            </div>
            <div class="portraits">
              <div class="card">
                <div class="avatar-container">
                  <v-img class="avatar p-bottom" src="../assets/pictures/cecile_avatar.jpg"></v-img>
                </div>
                <h2>Cécile Tric</h2>
                <h3>Présidente</h3>
                <h4>
                  <p>
                    Ancienne éditrice et directrice éditoriale chez Hachette
                    Education et chez Bordas, j’ai décidé de quitter mon poste
                    chez Hachette en 2021 pour lancer l’Ecran du savoir.
                  </p>
                  <p>
                    En visitant les lycées, j’ai souvent constaté que les
                    enseignants s’interrogent sur l’apport du numérique pour les
                    manuels scolaires. Je les ai aussi de plus en plus entendu
                    signaler leur besoin d'outils pour gérer « l’hétérogénéité »
                    de leurs classes et donner davantage d'autonomie aux élèves.
                  </p>
                  <p>
                    Avec l'Ecran du Savoir je souhaite concevoir des manuels
                    scolaires interactifs personnalisables, qui s'adaptent aux élèves et aux
                    enseignants. J'ai également à cœur d'offrir des contenus de
                    qualité pédagogique et scientifique, conçus grâce à
                    l'expérience des auteurs.
                  </p>
                </h4>
              </div>
              <div class="card">
                <div class="avatar-container">
                  <v-img class="avatar" src="../assets/pictures/pascal_avatar.jpg"></v-img>
                </div>

                <h2>Pascal Robbes</h2>
                <h3>CTO</h3>
                <h4>
                  <p>
                    Passionné de nouvelles technologies, j'ai été développeur
                    puis directeur informatique dans des agences web et
                    concepteur/développeur indépendant.
                  </p>
                  <p>J’ai mis en place de
                    multiples projets d’envergure dans des domaines tels que
                    l’information, les médias digitaux, le e-commerce ou la
                    formation.</p>
                  <p>
                    Intégrer l'Ecran du Savoir me permet de mettre à profit plus
                    de 20 ans d'expérience en développement dans un projet
                    d'éducation innovant, domaine qui me tient particulièrement
                    à cœur.
                  </p>

                </h4>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <v-container fluid class="bg-lightgray" v-if="manifest.content">
      <v-container>
        <v-row>
          <v-col cols="12">
            <h2 class="secondaire" v-html="manifest.title"></h2>
            <div v-html="manifest.content"></div>
          </v-col>
        </v-row>
      </v-container>

    </v-container>
    <v-container fluid class="bg-lightgray">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="centered-content column">
              <img
                alt="Ils parlent de nous"
                :src="require('../assets/pictures/speaker.png')"
                style="width: 150px; height: auto"
                class="mb-2"
              />
            </div>
            <h2 class="secondaire">Ils parlent de nous</h2>

            <div class="centered-content column ils-parlent mt-5">
              <img
                alt="Paris&Co"
                :src="require('../assets/pictures/parisandco.png')"
                style="width: 200px; height: auto"
              />
              <p>
                <a
                  href="https://www.parisandco.paris/a-la-une/actualites/ces-8-personnalites-font-bouger-le-monde-des-industries-creatives"
                  target="_blank"
                >
                  Ces 8 personnalités font bouger le monde des industries
                  créatives
                  <v-icon class="ml-1">mdi-open-in-new</v-icon>
                </a
                >
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <v-container fluid class="std">
      <v-container>
        <v-row>
          <v-col cols="12">
            <h2 class="secondaire">ils nous accompagnent</h2>
            <div>
              <v-layout class="row wrap mb-12">
                <v-flex xs12 md3>
                  <div class="logos-part_1">
                    <div class="bpi">
                      <img
                        alt="BPI"
                        :src="require('../assets/logos/logo_bpi.png')"
                      />
                    </div>
                    <div class="edtech">
                      <v-img
                        alt="EdTech France"
                        :src="require('../assets/logos/logo_edtech2.png')"
                      />
                    </div>
                  </div>
                </v-flex>
                <v-flex xs12 md6>
                  <div class="logos-container">
                    <div class="logos-part_2">
                      <div class="pl-4">
                        <img
                          alt="PIE"
                          :src="require('../assets/pictures/logo-pie.png')"
                        />
                      </div>
                      <div class="labo pr-4">
                        <img
                          alt="Labo de l'édition"
                          :src="
                            require('../assets/logos/logo_labo_de_ledition.png')
                          "
                        />
                      </div>
                    </div>
                    <div class="logos-part_3">
                      <div>
                        <img
                          alt="afinef"
                          :src="require('../assets/pictures/logo-afinef.png')"
                        />
                      </div>
                    </div>
                  </div>
                </v-flex>
                <v-flex xs12 md3>
                  <div class="logos-part_4">
                    <img
                      alt="perfaccom"
                      :src="require('../assets/logos/logo_perfaccom.png')"
                    />
                  </div>
                </v-flex>
              </v-layout>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <v-container fluid class="bg-lightgray">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="section-couvertures">
              <div>
                <div class="centered-content mt-6 mb-4">
                  <div>
                    <a
                      target="_blank"
                      href="https://apis.lecrandusavoir.fr/api/connect_demo_user/histoire2de"
                      class="btn-link w100"
                    >
                      <strong>Je découvre le manuel</strong>
                      <span>Histoire 2de</span>
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div class="centered-content mt-6 mb-4">
                  <div>
                    <a
                      target="_blank"
                      href="https://apis.lecrandusavoir.fr/api/connect_demo_user/geographie2de"
                      class="btn-link w100"
                    >
                      <strong>Je découvre le manuel</strong>
                      <span>Géographie 2de</span>
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div class="centered-content mt-6 mb-4">
                  <div>
                    <a
                      target="_blank"
                      href="https://apis.lecrandusavoir.fr/api/connect_demo_user/histoiregeographie2de"
                      class="btn-link w100"
                    >
                      <strong>Je découvre le manuel</strong>
                      <span>Histoire/Géographie 2de</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="offre">
              <p><strong>Offre de découverte</strong></p>
              <p>Une licence offerte pour 20 manuels achetés</p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Home",
  title: "L'écran du savoir - Qui sommes-nous",
  props: ["email_contact"],
  data: () => ({
    manifest: {
      content: null,
      title: null
    },
  }),
  mounted() {
    window.scrollTo(0, 0);
    this.$gtag.event("Qui sommes-nous", {method: "Google"});
    this.fetchManifestWordPressContent();
  },
  methods: {
    fetchManifestWordPressContent() {
      const wordpressUrl = 'https://blog.lecrandusavoir.fr//wp-json/wp/v2/pages/96';
      axios.get(wordpressUrl)
        .then(response => {
          this.manifest.title = response.data.title.rendered;
          this.manifest.content = response.data.content.rendered;
        })
        .catch(error => {
          console.error('Erreur lors de la récupération du contenu WordPress :', error);
        });
    },
  }
};
</script>

<style lang="scss" scoped>
span {
  display: block;
}

.avatar-container {
  display: flex;
  justify-content: space-around;
  align-items: center;

  .avatar {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;

    &.p-bottom .v-image__image {
      background-position:bottom!important;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}


</style>
